@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Raleway&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-logo-container:hover {
  animation: blur-image 1.2s;
}

.App-header {
  background-color: #117778;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(6px + 2vmin);
  font-family: 'Raleway';
}

.holding-container {
  max-width: 1024px;
  
  @media(max-width: 1024px) {
    padding: 20px;
  }
}

.subtitle {
  font-size: calc(4px + 2vmin);
  color: #212121;
}

.link-wrapper {
  position: relative;
  display: block;
  padding: 20px 0;
}

.link-hover {
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: #212121;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.5s;
  }

  &:hover {
    &:after {
      transform-origin: bottom left;
      transform: scaleX(1);
    }
  }
}

.App-link {
  position: relative;
  color: #212121;
  font-weight: 700;
  text-decoration: none;
  display: inline-block;
  padding: 10px 0;
}

@keyframes blur-image {
  0% {
    filter: blur(0px);
  }

  50% {
    filter: blur(2px);
  }

  100% {
    filter: blur(0px);
  }
}